<template>
  <div class="list-object !px-5 py-6 h-full">
    <Empty
      v-if="notHaveAccess"
      :description="t('group-management.not-have-access')"
      class="mt-10"
    />
    <template v-else>
      <div class="flex mb-6 justify-end">
        <a-input
          size="large"
          :placeholder="t('group-management.placeholder-input-search')"
          v-model:value="formState.name"
          class="w-[240px] ant-input-sm rounded-[8px] bg-ems-tag1 border-none mr-4"
          v-if="!collapsed"
          @pressEnter="searchIO"
          :maxlength="255"
          :show-count="true"
          :allow-clear="true"
          @change="delaySearch"
        >
          <template #prefix>
            <img
              src="@/static/img/group-management/input-search.svg"
              alt="input-suffix"
              height="16"
              width="16"
            />
          </template>
        </a-input>
        <cds-select
          :options="IO_TYPE"
          v-model:value="formState.type"
          :placeholder="t('group-management.select-type-placeholder')"
          class="ant-input-sm mr-4 !w-[240px]"
          @select="searchIO"
        />
        <img
          v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.ADD_OBJECT)"
          src="@/static/img/group-management/create-icon.svg"
          alt="create-icon"
          width="36"
          height="36"
          @click="create"
          class="cursor-pointer"
        />
        <!-- <div class="text-white">Test</div> -->
      </div>
      <div
        v-if="listIO.length === 0 && isLoading"
        class="spin bg-transparent mt-10 h-[auto]"
      >
        <a-spin />
      </div>
      <Empty
        v-else-if="listIO.length === 0"
        :description="t('group-management.list-object-empty')"
        class="mt-10"
      />
      <div v-else class="h-[calc(100%-20px)] overflow-y-auto flex flex-col">
        <div
          class="grid 2xl:grid-cols-3 md:grid-cols-2 grid-cols-1 !gap-5 grid-cols-1 mb-[30px] pr-[2px] pr-[2px]"
        >
          <IOItem v-for="(data, idx) in listIO" :key="data.id" :item="data" />
        </div>
        <div v-if="isLoading" class="spin bg-transparent h-[auto]">
          <a-spin />
        </div>
        <div class="w-full flex justify-center">
          <a-button
            type="primary"
            class="!text-ems-main2 !border-ems-main2 text-base font-semibold"
            ghost
            @click="viewMore"
            v-if="totalIOInGroup > 6 * (page + 1)"
            >{{ t('common.view-more') }}
          </a-button>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { i18n } from '@/main';
import { reactive, computed, onMounted, ref, inject, onUnmounted } from 'vue';
import IOItem from './IOItem.vue';
import { useStore } from 'vuex';
import { IO_TYPE } from '@/config/Constant.js';
import { debounce } from 'lodash';
import { checkHasPermission } from '@/util/common-utils';
import { OBJECT_GROUP_PERM } from '@/util/common-constant';
import Empty from '@/components/empty/index.vue';

const { t } = i18n.global;
const { state, dispatch } = useStore();
const eventBus = inject('eventBus');

const props = defineProps({
  selectedGroup: {
    type: Object,
    default: {},
  },
});

const perms = computed(() => state.auth.userInfo.permissions);
const listIO = computed(() => state.ioGroup.listIOInGroup);
const isLoading = computed(() => state.ioGroup.loading);
const totalIOInGroup = computed(() => state.ioGroup.totalIOInGroup);
const notHaveAccess = computed(() => state.ioGroup.notHaveAccess);
const page = ref(0);
const DEBOUNCE_TIME = 300;
const delaySearch = debounce(() => {
  searchIO();
}, DEBOUNCE_TIME);
onMounted(() => {
  dispatch('ioGroup/getListIO', {
    groupId: props.selectedGroup.id,
    ...formState,
    page: 0,
    size: 6,
  });
  eventBus.on('list-io-group-refresh-filter', () => {
    formState.name = '';
    formState.type = null;
    page.value = 0;
  });
});
onUnmounted(() => {
  eventBus.off('list-io-group-refresh-filter');
});
const formState = reactive({
  name: '',
  type: null,
});
const emits = defineEmits(['create-io']);
const create = () => {
  emits('create-io');
};
const viewMore = () => {
  page.value++;
  dispatch('ioGroup/getListIO', {
    groupId: props.selectedGroup.id,
    ...formState,
    page: page.value,
    size: 6,
  });
};
const searchIO = () => {
  page.value = 0;
  let data = {
    groupId: props.selectedGroup.id,
    ...formState,
    page: page.value,
    size: 6,
  };
  if (formState.type === '') {
    data.type = null;
  }
  dispatch('ioGroup/getListIO', data);
};
</script>
<style lang="scss">
.list-object .ant-select {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  border-radius: 8px;
  height: 38px;
  width: 100%;
}
.list-object .ant-input-sm input.ant-input {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.list-object .ant-input-sm input.ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.list-object .ant-select-selector {
  background-color: #373737 !important;
  border: none !important;
  border-radius: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.list-object .ant-select-selector .ant-select-selection-search {
  background-color: #373737 !important;
}
.list-object .ant-select-selector .ant-select-selection-placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #c4c4c4 !important;
}
.list-object .ant-select-selector input {
  height: 24px !important;
}
.list-object .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.list-object .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
</style>
