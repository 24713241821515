<template>
  <div
    class="io-item overflow-hidden !p-5 bg-ems-tag1 flex !gap-5 items-center justify-start rounded-[8px] group relative z-0 border-1 border-solid border-[transparent] hover:border-ems-gray500 hover:bg-ems-gray700 max-h-[170px]"
  >
    <img
      :src="
        !item.imagesAvatar
          ? require('@/static/img/no-img.png')
          : item.imagesAvatar
      "
      alt="avatar"
      width="120"
      height="120"
      class="w-[120px] h-[120px] flex-shrink-0 object-cover"
    />
    <div class="flex flex-col gap-3 flex-1">
      <a-tooltip v-if="nameItem.length > 40" overlayClassName="tooltip-name-io">
        <template #title
          ><span class="font-semibold text-lg text-ems-gray100">{{
            nameItem
          }}</span></template
        >
        <span class="font-semibold text-lg text-ems-gray100">{{
          nameItem.slice(0, 40) + '...'
        }}</span>
      </a-tooltip>
      <span v-else class="font-semibold text-lg text-ems-gray100">{{
        nameItem
      }}</span>
      <div
        class="px-[10px] py-[3px] text-ems-gray100 block bg-ems-gray600 rounded-[4px] w-[fit-content]"
      >
        {{ t(IO_TYPE[item.type].label) }}
      </div>
    </div>
    <a-popover
      placement="bottomLeft"
      class="absolute top-3 right-3"
      overlayClassName="popup-menu-io-item"
    >
      <template #content>
        <div class="io-menu-item rounded-t-[8px]" @click="tracingObject">
          <cds-feather-icons type="search" size="24" class="text-ems-gray100" />
          <span class="text-base text-ems-gray100 py-3 inline-block">{{
            t('group-management.search')
          }}</span>
        </div>
        <div class="w-full h-[1px] bg-ems-gray500"></div>
        <div class="io-menu-item" @click="viewDetail">
          <cds-feather-icons type="eye" size="24" class="text-ems-gray100" />
          <span class="text-base text-ems-gray100 py-3 inline-block">{{
            t('group-management.view-detail')
          }}</span>
        </div>
        <div
          v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.REMOVE_OBJECT)"
          class="w-full h-[1px] bg-ems-gray500"
        ></div>
        <div
          v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.REMOVE_OBJECT)"
          class="io-menu-item rounded-b-[8px]"
          @click="visible = true"
        >
          <cds-feather-icons
            type="trash-2"
            size="24"
            class="text-ems-gray100"
          />
          <span class="text-base text-ems-gray100 py-3 inline-block">{{
            t('group-management.delete')
          }}</span>
        </div>
      </template>
      <cds-feather-icons
        type="more-horizontal"
        size="24"
        class="text-ems-gray700 rounded-full bg-ems-gray200 hidden group-hover:block cursor-pointer icon-menu-custom"
      />
    </a-popover>
  </div>
  <cds-modal
    :visible="visible"
    :mask-closable="false"
    hide-footer
    @onCancel="visible = false"
    class="bg-ems-gray800 pb-0"
  >
    <ModalConfirm
      ref="modal"
      @confirm="deleteObj"
      @onClose="visible = false"
      :title="
        t('group-management.confirm-delete-io-in-group', {
          name: nameItem,
        })
      "
    ></ModalConfirm>
  </cds-modal>
</template>
<script setup>
import { i18n } from '@/main';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import { IO_TYPE } from '@/config/Constant.js';
import { useRouter } from 'vue-router';
import {
  TAB_KEY,
  OBJECT_TYPE,
  FORM_MODE,
  OBJECT_GROUP_PERM,
} from '@/util/common-constant';
import { checkHasPermission } from '@/util/common-utils';

const { t } = i18n.global;
const { state, dispatch } = useStore();
const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});
const visible = ref(false);
const perms = computed(() => state.auth.userInfo.permissions);
const nameItem = computed(() => {
  if (props.item.type === OBJECT_TYPE.VEHICLE) {
    return props.item.licensePlate ? props.item.licensePlate : props.item.id;
  } else {
    return props.item.name ? props.item.name : props.item.id;
  }
});
const tracingObject = async () => {
  await dispatch('ioManagement/tracingObject', props.item.id);
  router.push({
    path: '/trace-management/object-tracing/detail',
    query: {
      id: props.item.id,
      tab: TAB_KEY.INFO,
    },
  });
};

const router = useRouter();
const viewDetail = () => {
  router.push({
    path: '/io-management/object-management/edit',
    query: {
      id: props.item.id,
      type: props.item.type,
      mode: FORM_MODE.VIEW,
    },
  });
};

const deleteObj = () => {
  dispatch('ioGroup/deleteIOInGroup', props.item.id);
  visible.value = false;
};
</script>
<style lang="scss">
.io-menu-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 123, 123, var(--tw-bg-opacity));
}
.io-menu-item {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.popup-menu-io-item .ant-popover-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-radius: 8px;
  padding: 0px;
  box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 1);
}
.popup-menu-io-item .ant-popover-arrow-content {
  background-color: #434343 !important;
}
.tooltip-name-io .ant-tooltip-content {
  box-shadow: 0 4px 84px 0 rgba(255, 255, 255, 0.2);
}
.tooltip-name-io .ant-tooltip-arrow-content {
  background-color: #262626 !important;
}
.tooltip-name-io .ant-tooltip-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
  border-style: none;
}
</style>
